import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"

import LinePost from "../components/linePost";

export default function Blog({ data }) {
    const { group: gruposAnos } = data.blog;

    gruposAnos.sort((a, b) => {
        if (a.fieldValue < b.fieldValue) {
            return 1;
        }
        if (a.fieldValue > b.fieldValue) {
            return -1;
        }

        return 0
    })

    return (
        <Layout>
            <SEO title="Blog" />
            <section>
                <h1>
                    Blog
                </h1>
            </section>
            {gruposAnos.map((grupo, index) => (
                <section key={index}>
                    <div sx={{
                        marginBottom: 1,
                        fontSize: 5,
                        fontWeight: 700,
                        borderBottomWidth: '2px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'muted',
                    }}>
                        { grupo.fieldValue }
                    </div>
                    <div>
                        {grupo.posts.map((post) => (
                            <LinePost
                                key={post.id} 
                                titulo={post.frontmatter.title}
                                data={post.frontmatter.date}
                                url={post.fields.slug}
                            />
                        ))}
                    </div>
                </section>
            ))}
        </Layout>
    )
}

export const blogQuery = graphql`
{
    blog: allMdx(sort: {order: DESC, fields: frontmatter___date}) {
      group(field: fields___year) {
        totalCount
        posts: nodes {
          frontmatter {
            title
            tags
            date(locale: "PT", formatString: "DD/MM/YYYY")
            author
          }
          id
          fields {
            slug
          }
        }
        fieldValue
      }
    }
  }
`